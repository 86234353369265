.audio-switch {
    margin: 40px 100px;
    position: fixed;
    display: flex;
    bottom: 0;
    justify-content: space-between;
    right: 0;
    z-index: 12;
    align-items: flex-end;
}

.audio-switch-button  {
    cursor: pointer;
}

.audio-switch-button img  {
    width: 40px;
    height: 40px;
}


@media screen and (max-width: 1919px) {
    .audio-switch {
        margin: 40px 60px;
    }

    .audio-switch-button img {
        width: 24px;
        height: 24px;
    }
}

@media screen and (max-width: 1439px) {
    .audio-switch {
        margin: 40px 40px;
    }

    .audio-switch-button img {
        width: 24px;
        height: 24px;
    }
}

@media screen and (max-width: 1023px) {
    .audio-switch {
        margin: 30px 40px;
    }

    .audio-switch-button img {
        width: 24px;
        height: 24px;
    }
}

@media screen and (max-width: 767px) {
    .audio-switch {
        margin: 20px 20px;
    }

    .audio-switch-button img {
        width: 20px;
        height: 20px;
    }

}


