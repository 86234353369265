.contact {
    padding: 0 269px;
    position: fixed;
    display: flex;
    top: 30%;
    width: 100vw;
}

.contact_info {
    width: 50%;

}

.contact_info_title {
    font-size: 78px;
    font-weight: 600;
    margin: 0 0 30px 0;
}

.contact_form_title {
    font-size: 54px;
    font-weight: 600;
    margin: 0 0 30px 0;
}


.contact_info_name {
    font-weight: 500;
    font-size: 26px;
    margin-bottom: 16px;

}

.contact_info_address {
    font-weight: 400;
    font-size: 26px;
    line-height: 130%;
    margin-bottom: 30px;


}

.contact_email {
    color: white;
    text-decoration: none;
    margin-bottom: 60px;
    font-size: 26px;
    width: fit-content;
    display: block;
}

.contact_link {
    color: white;
    text-decoration: none;
    font-size: 26px;
    width: fit-content;
    display: block;
}

.link_to {
    outline: none;
    text-decoration: none;
    font-size: 26px;
    display: flex;
    align-items: center;
    color: white;
    width: fit-content;
    margin-bottom: 60px;
}

.contact_form {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.contact_form input{
    width: 100%;
    background: none;
    font-family: 'GeneralSans', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    border: none;
    color: white;
    border-bottom: 1px solid white;
    margin-bottom: 50px;
    padding: 0 0 14px 0;
    outline: none;
}

.contact_form input::placeholder{

    color: white;

}



.submit_button {
    color: white;
    border: 1px solid white;
    font-family: 'GeneralSans', sans-serif;
    outline: none;
    background: none;
    width: fit-content;
    border-radius: 40px;
    font-size: 20px;
    padding: 0.9em 2em;
    cursor: pointer;
}

/*.footer__email {*/
/*    font-size: 16px;*/
/*    font-weight: 400;*/
/*}*/

/*.footer__email:link {*/
/*    color: #fff;*/
/*    background-color: transparent;*/
/*    text-decoration: none;*/
/*}*/



@media screen and (max-width: 1919px) {

    .contact {
        padding: 0 190px;
        top: 30%;
    }

    .contact_info {
        width: 50%;

    }

    .contact_info_title {
        font-size: 42px;
        margin: 0 0 34px 0;
    }

    .contact_form_title {
        font-size: 42px;
        margin: 0 0 34px 0;
    }


    .contact_info_name {
        font-size: 20px;
        margin-bottom: 12px;

    }

    .contact_info_address {
        font-size: 20px;
        margin-bottom: 30px;

    }

    .contact_email {
        margin-bottom: 50px;
        font-size: 20px;
    }

    .contact_link {
        font-size: 20px;
    }


    .link_to {
        margin-bottom: 50px;
        font-size: 20px;
    }

    .contact_form {
        width: 50%;
    }

    .contact_form input{
        font-size: 20px;
        margin-bottom: 50px;
        padding: 0 0 14px 0;
    }

    .submit_button {
        font-size: 18px;
        padding: 1em 2.5em;
    }

}

@media screen and (max-width: 1439px) {

    .contact {
        padding: 0 60px;
        top: 30%;
    }

    .contact_info {
        width: 50%;

    }

    .contact_info_title {
        font-size: 42px;
        margin: 0 0 34px 0;
    }

    .contact_form_title {
        font-size: 42px;
        margin: 0 0 34px 0;
    }


    .contact_info_name {
        font-size: 20px;
        margin-bottom: 12px;

    }

    .contact_info_address {
        font-size: 20px;
        margin-bottom: 30px;

    }

    .contact_email {
        margin-bottom: 50px;
        font-size: 20px;
    }

    .contact_link {
        font-size: 20px;
    }

    .link_to {
        margin-bottom: 50px;

        font-size: 20px;
    }

    .contact_form {
        width: 50%;
    }

    .contact_form input{
        font-size: 20px;
        margin-bottom: 50px;
        padding: 0 0 14px 0;
    }

    .submit_button {
        font-size: 18px;
        padding: 1em 2.5em;
    }

}

@media screen and (max-width: 1023px) {

    .contact {
        padding: 0 40px;
        top: 30%;
    }

    .contact_info {
        width: 50%;

    }

    .contact_info_title {
        font-size: 36px;
        margin: 0 0 34px 0;
    }

    .contact_form_title {
        font-size: 36px;
        margin: 0 0 34px 0;
    }


    .contact_info_name {
        font-size: 16px;
        margin-bottom: 10px;

    }

    .contact_info_address {
        font-size: 16px;
        margin-bottom: 26px;

    }

    .contact_email {
        margin-bottom: 50px;
        font-size: 16px;
    }

    .contact_link {
        font-size: 16px;
    }

    .link_to {
        margin-bottom: 50px;
        font-size: 18px;
    }

    .contact_form {
        width: 50%;
    }

    .contact_form input{
        font-size: 16px;
        margin-bottom: 46px;
        padding: 0 0 12px 0;
    }

    .submit_button {
        font-size: 18px;
        padding: 1em 2.5em;
    }

}

@media screen and (max-width: 767px) {

    .contact {
        padding: 0 20px;
        top: 100px;
        flex-direction: column;
    }

    .contact_info {
        width: 100%;
        margin-bottom: 34px;
    }

    .contact_info_title {
        font-size: 36px;
        margin: 0 0 24px 0;
    }

    .contact_form_title {
        font-size: 36px;
        margin: 0 0 24px 0;
    }


    .contact_info_name {
        font-size: 14px;
        margin-bottom: 10px;

    }

    .contact_info_address {
        font-size: 14px;
        margin-bottom: 14px;

    }

    .contact_email {
        margin-bottom: 30px;
        font-size: 14px;
    }

    .contact_link {
        font-size: 14px;
    }


    .link_to {
        margin-bottom: 30px;

        font-size: 16px;
    }

    .contact_form {
        width: 100%;
    }

    .contact_form input{
        font-size: 12px;
        margin-bottom: 30px;
        padding: 0 0 10px 0;
    }

    .submit_button {
        font-size: 12px;
        padding: 1.25em 2.75em;
    }
}

@media (max-width: 1295px) and (max-height: 500px) {

    .contact {
        padding: 0 20px;
        top: 15%;
        flex-direction: row;
    }

    .contact_info {
        width: 50%;
        margin-bottom: 0;
    }

    .contact_info_title {
        font-size: 36px;
        margin: 0 0 24px 0;
    }

    .contact_form_title {
        font-size: 36px;
        margin: 0 0 24px 0;
    }


    .contact_info_name {
        font-size: 14px;
        margin-bottom: 10px;

    }

    .contact_info_address {
        font-size: 14px;
        margin-bottom: 14px;

    }

    .contact_email {
        margin-bottom: 30px;
        font-size: 14px;
    }

    .link_to {
        margin-bottom: 30px;

        font-size: 16px;
    }

    .contact_form {
        width: 50%;
    }

    .contact_form input{
        font-size: 12px;
        margin-bottom: 30px;
        padding: 0 0 10px 0;
    }

    .submit_button {
        font-size: 12px;
        padding: 1.25em 2.75em;
    }

}

