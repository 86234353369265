

.cases-scroll-wrapper {
    height: 100%;
    width: 100vw;
    position: absolute;
    /*overflow-y: auto;*/
    overflow-x: hidden;
}

.cases-scroll-container {
    position: absolute;
    top: 0;
}

.cases_container {
    padding-top: 20vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    margin-top: 60vh;
    margin-bottom: 100vh;
}

.cases_wrapper {
    width: 1385px;
}


.case_top {
    position: absolute;
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /*height: 100vh;*/
    /*width: 100vw;*/
    /*max-height: -webkit-fill-available;*/
    /*opacity: 0;*/
}

.segment_title {
    font-size: 54px;
    font-weight: 500;
    margin-bottom: 28px;
    margin-top: 0;
    /*margin-left: 268px;*/
    /*width: 80%;*/
}

.about_link_arrow {
    height: 1.2em;
    margin-left: 0.4em;
}

.link {
    outline: none;
    text-decoration: none;
    font-size: 24px;
    display: flex;
    align-items: center;
    width: fit-content;
    color: white;
    padding: 0;
}

.link .arrow {
    height: 24px;
    width: 24px;
    margin-left: 8px;
}



.scrollmore {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
    /*width: 100vw;*/
    text-align: center;
    animation: float 6s ease-in-out infinite;
    /*justify-content: center;*/
}

.case_content {
    /*position: absolute;*/
    /*top: 220vh;*/
    /*right: 0;*/
    display: flex;
    /*width: 100vw;*/
    justify-content: center;
    margin-bottom: 100px;
    /*left: 0;*/
}

.case_info {
    position: absolute;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    top: 55%;
    transform: translate(0, -50%);
    left: 160px;
    width: 450px;

}

.case_title {
    font-size: 78px;
    font-weight: 600;
    line-height: 0.9;
    font-kerning: none;
    width: 550px;
    margin: 0 0 32px;
}

.case_info_text {
    font-kerning: none;
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 28px;
    line-height: 140%;
}

.case_info_text_0 {
    font-weight: 500;

}

.case_info_text_1 {

}

.case_images_container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 76px;
}

.case_image {
    margin-bottom: 44px;
    overflow: hidden;
    /*transform: scale(1.1);*/
    display: flex;
    align-items: center;
}

.case_image_little {
    width: 670px;
    /*height: 460px;*/
    background-color: #EB4C38;
}

.case_image_big {
    width: 1384px;
    /*height: 740px;*/
    background-color: #EB4C38;
}

.case_image_little_square {
    width: 670px;
    /*height: 610px;*/
}


.about_container {
    /*position: absolute;*/
    /*    top: 150vh;*/
    /*align-items: center;*/
    opacity: 0;
    flex-direction: column;
    display: flex;
    /*width: 100vw;*/
    /*justify-content: center;*/
    /*margin-bottom: 100px;*/
}

.about_info {
    display: flex;
    margin-bottom: 110px;
}

.about_info p {
    margin: 0;
    /*font-family: 'Abel', sans-serif;*/


}

.about_info_text {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px;
}

.about_info_text p {
    width: 50%;
    margin: 0;
    font-size: 20px;
    line-height: 140%;
    padding-right: 60px;
}

.about_quote {
    font-size: 32px;
    width: 50%;
    line-height: 130%;
    font-style: italic;
}

.additional_info {
    width: 50%;
    padding-right: 30px;
}

.additional_info_first {
    font-size: 32px;
    font-style: italic;

    font-weight: 400;
}

.additional_info_second {
    width: 50%;
    font-size: 20px;
    font-weight: 400;
}

.video {
    height: auto !important;
}


@keyframes float {
    0% {
        transform: translatey(0px);
    }
    50% {
        transform: translatey(-20px);
    }
    100% {
        transform: translatey(0px);
    }
}



@media screen and (max-width: 1919px) {


    .cases_wrapper {
        width: 1160px;
    }

    .case_images_container {
        margin-bottom: 80px;
    }

    .case_image {
        margin-bottom: 20px;
    }

    .case_image_little {
        width: 570px;
        /*height: 360px;*/
    }

    .case_image_little_square {
        width: 570px;
        /*height: 540px;*/
    }

    .case_image_big {
        width: 1160px;
        /*height: 600px;*/
    }

    .case_info {
        left: 105px
    }

    .case_info_text {
        width: 404px;
        font-size: 20px;
        margin-bottom: 18px;
    }

    .case_title {
        font-size: 52px;
        width: 404px;
        margin: 0 0 24px;
    }

    .scrollmore {
        bottom: 30px;
        font-size: 12px;
    }

    .segment_title {
        /*margin-left: 189px;*/
        font-size: 42px;
        margin-bottom: 24px;
    }

    .about_info {
        margin-bottom: 110px;
    }

    .about_info_text p {
        width: 50%;
        margin: 0;
        font-size: 20px;
        line-height: 140%;
        padding-right: 40px;
    }

    .additional_info {
        padding-right: 20px;
    }

    .additional_info_first {
        font-size: 32px;
    }

    .additional_info_second {
        font-size: 20px;
    }

    .about_quote {
        font-size: 26px;
    }

    .link {
        font-size: 16px;
    }
}


@media screen and (max-width: 1439px) {
    .cases_wrapper {
        width: 696px;
    }

    .case_images_container {
        margin-bottom: 68px;
    }

    .case_image {
        margin-bottom: 12px;
    }

    .case_image_little {
        width: 342px;
        /*height: 246px;*/

    }

    .case_image_little_square {
        width: 342px;
        /*height: 356px;*/
    }

    .case_image_big {
        width: 696px;
        /*height: 360px;*/
    }
    .scrollmore {
        bottom: 40px;
        font-size: 12px;
    }

    .segment_title {
        font-size: 42px;
        margin-bottom: 30px;
        /*margin-left: 268px;*/
    }

    .case_info {
        left: 85px;
        width: 321px;

    }

    .case_info_text {
        font-size: 16px;
        margin-bottom: 18px;
    }

    .about_quote {
        font-size: 22px;
    }


    .additional_info {
        padding-right: 30px;
    }

    .additional_info_first {
        font-size: 24px;
    }

    .about_info_text p {
        font-size: 16px;
    }

    .additional_info_second {
        font-size: 16px;
    }

}

@media screen and (max-width: 1023px) {
    .cases_wrapper {
        width: 688px;
    }


    .case_image {
        margin-bottom: 14px;
    }

    .case_images_container {
        margin-bottom: 66px;
    }

    .case_image_little {
        width: 338px;
        /*height: 240px;*/
    }

    .case_image_little_square {
        width: 338px;
        /*height: 356px;*/
    }

    .case_image_big {
        width: 688px;
        /*height: 360px;*/
    }
    .case_info {
        left: 0;
        right: 0;
        /*transform: translate(-50%, 0);*/
        width: 100%;
        align-items: center;
        display: flex;
        flex-direction: column;
        top: auto;
        bottom: 10%;
        transform: none;
    }


    .segment_title {
        font-size: 36px;
        margin-bottom: 30px;
        /*width: 95%;*/
        /*margin-left: 268px;*/
    }

    .about_info {
        /*width: 95%;*/
        flex-direction: column;
        margin-bottom: 70px;
    }

    .case_info {
        width: 100%;
        text-align: center;
    }

    .case_title {
    }

    .case_info_text {
        max-width: 480px;
        width: 100%;
        margin: 0 0 16px;
    }
    .case_title {
        font-size: 36px;
        margin: 0 0 17px;
    }
    .scrollmore {
        font-size: 12px;
    }

    .about_info_text {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 14px;
    }

    .about_info_text p {
        width: 100%;
        font-size: 16px;
        padding-right: 200px;
        margin-bottom: 12px;
    }

    .about_quote {
        font-size: 20px;
        width: 100%;
        padding-right: 200px;
        margin-top: 36px;
    }


    .additional_info {
        /*padding: 0 10%;*/
        width: 100%;
    }

    .additional_info_first {
        font-size: 24px;
        margin:  0 0 34px 0 !important;
    }

    .additional_info_second {
        font-size: 16px;
    }

}

@media screen and (max-width: 767px) {

    .cases_wrapper {
        width: 335px;
    }

    .case_images_container {
        margin-bottom: 48px;
    }

    .case_image_little {
        width: 335px;
        /*height: 190px;*/
    }

    .case_image_little_square {
        width: 335px;
        /*height: 300px;*/
    }

    .case_image_big {
        width: 335px;
        /*height: 190px;*/
    }

    .case_info {
        left: 0;
        right: 0;
        width: 100%;
        text-align: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        top: auto;
        transform: none;
    }

    .segment_title {
        font-size: 32px;
        /*margin-left: 268px;*/
    }

    .case_title {
    }

    .case_info_text {
        max-width: 355px;
        padding: 0 15px;
        margin: 0 0 9px;
    }

    .scrollmore {
        bottom: auto;
        top: 95%;
        font-size: 10px;
    }
    .case_title {
        font-size: 32px;
        margin: 0 0 17px;
    }

    .about_quote {
        font-size: 20px;
        padding-right: 0;
        margin-top: 28px;

    }

    .about_info {
        margin-bottom: 58px;
    }

    .about_info_text {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 8px;
    }

    .about_info_text p {
        width: 100%;
        font-size: 14px;
        padding-right: 0;
        margin-bottom: 8px;
    }

    .additional_info {
        width: 100%;
        padding: 0;
    }

    .additional_info_first {
        font-size: 20px;
        margin:  0 0 20px 0 !important;
    }

    .additional_info_second {
        font-size: 14px;
    }

}

@media (max-width: 995px) and (max-height: 500px) {

    .cases_wrapper {
        width: 688px;
    }

    .case_images_container {
        margin-bottom: 58px;
    }

    .case_image_little {
        width: 338px;
        /*height: 240px;*/
    }

    .case_image_little_square {
        width: 335px;
        /*height: 330px;*/
    }

    .case_image_big {
        width: 688px;
        /*height: 360px;*/
    }

    .about_container {
        /*justify-content: center;*/
    }

    .case_info {
        left: 80px;
        width: 100%;
        display: block;
        bottom: 61px;
    }

    .about_quote {
        font-size: 20px;
        padding-right: 0;
        margin-top: 28px;
    }

    .segment_title {
        font-size: 32px;
    }

    .case_title {
        width: 258px;
    }

    .case_info_text {
        width: 258px;
        margin: 0 0 9px;
    }

    .scrollmore {
        bottom: auto;
        top: 90%;
        font-size: 10px;
    }

    .case_title {
        font-size: 32px;
        margin: 0 0 17px;
    }

    .about_info {
        margin-bottom: 58px;
    }

    .about_info_text {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 8px;
    }

    .about_info_text p {
        width: 100%;
        font-size: 14px;
        padding-right: 0;
        margin-bottom: 8px;
    }

    .segment_title {
        width: 80%;
        /*margin-left: 268px;*/
    }

    .about_info {
        width: 80%;
    }


    .additional_info {
        width: 100%;
        padding: 0 10%;
    }

    .additional_info_first {
        font-size: 24px;
        margin:  0 0 20px 0 !important;
    }

    .additional_info_second {
        font-size: 12px;
    }

}