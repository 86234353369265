.container__loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container__loading div{
    font-size: 18px;
}

.loading_bar_container {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.percents {
    font-weight: 500;
    font-size: 36px !important;
    margin-bottom: 4px;
    display: flex;

}

.percents-numbers-container {
    width: 50px;
    /*overflow: hidden;*/

}

.percents-numbers {
    position: relative;
    font-size: 36px !important;
    width: 50px;
    height: 1.1em;
    overflow: hidden;

}

.letter.letter1 {
    left: 25px;
    /*overflow: hidden;*/

}



.next-percents {
    position: absolute;
    display: flex;
    font-size: 36px !important;
}

.current-percents {
    display: flex;
}

.next-percents .letter {
    top: -1.1em;
}

.letter {
    position: absolute;
    text-align: center;
    width: 25px;
    font-weight: 500;
    font-family: 'TTNormsPro', sans-serif;
    font-size: 36px !important;
}

.percent-symbol {
    font-family: 'TTNormsPro', sans-serif;

}


.launch_buttons {
    display: flex;
    align-items: center;
}

.launch_button {
     font-size: 14px;
     width: 160px;
     cursor: pointer;
     color: white;
     display: flex;
     flex-direction: column;
     align-items: center;
    text-align: center;
    margin: 60px;
 }

.launch_button img {
    width: 50px;
    height: 50px;
    margin-bottom: 11px;
}

.off_sound {
    opacity: 0.4;
}

.line {
    height: 48px;
    width: 1px;
    background-color: #364141;
}

@media screen and (max-width: 1919px) {

    .percents {
        font-size: 36px !important;
    }


    .launch_button {
        font-size: 10px;
        margin: 40px;
    }

}

@media screen and (max-width: 1439px) {
}

@media screen and (max-width: 1023px) {
}

@media screen and (max-width: 767px) {


    .loading_bar_container {
        font-size: 10px;
    }

    .percents-numbers-container {
        width: 42px;
    }

    .percents-numbers {
        font-size: 28px !important;
        width: 42px;
        height: 1em;
        overflow: hidden;

    }

    .percents {
        font-size: 28px !important;
    }

    .letter {
        width: 21px;
        font-size: 28px !important;
    }

    .letter.letter1 {
        left: 21px;
        /*overflow: hidden;*/

    }


    .launch_button {
        font-size: 10px;
        margin: 40px;
    }


    .launch_button img {
        width: 36px;
        height: 36px;
        margin-bottom: 8px;
    }



    .launch_buttons {
        flex-direction: column;
    }
    .line {
        height: 1px;
        width: 48px;
        background-color: #364141;
    }

}