.footer {
    padding: 60px 100px;
    position: fixed;
    display: flex;
    bottom: 0;
    justify-content: space-between;
    width: 100vw;
    align-items: flex-end;
}


.footer__left-side {
    font-weight: 400;
    opacity: 0;
    font-size: 24px;
    line-height: 130%;
    font-kerning: none;
}



/*.footer__email {*/
/*    font-size: 16px;*/
/*    font-weight: 400;*/
/*}*/

/*.footer__email:link {*/
/*    color: #fff;*/
/*    background-color: transparent;*/
/*    text-decoration: none;*/
/*}*/



@media screen and (max-width: 1919px) {
    .footer {
        padding: 50px 60px;
    }
    .footer__left-side {
        font-size: 18px;
        line-height: 130%;
    }

}

@media screen and (max-width: 1439px) {
    .footer {
        padding: 40px 40px;
    }
}

@media screen and (max-width: 1023px) {
    .footer {
        padding: 40px 40px;
    }
}

@media screen and (max-width: 767px) {
    .footer {
        padding: 20px 20px;
    }

    .footer__left-side {
        font-size: 12px;
        line-height: 130%;
    }
}


