@font-face {
  font-family: 'TTNormsPro';
  src: url('../public/fonts/TTNormsPro-Bold.woff') format('woff');
  font-weight: 600;
}

@font-face {
  font-family: 'TTNormsPro';
  src: url('../public/fonts/TTNormsPro-Medium.woff') format('woff');
  font-weight: 500;
}


@font-face {
  font-family: 'TTNormsPro';
  src: url('../public/fonts/TTNormsPro-Regular.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'ClashDisplay';
  src: url('../public/fonts/ClashDisplay-Medium.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'ClashDisplay';
  src: url('../public/fonts/ClashDisplay-Semibold.woff') format('woff');
  font-weight: 600;
}

@font-face {
  font-family: 'Abel';
  src: url('../public/fonts/Abel-Regular.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'GeneralSans';
  src: url('../public/fonts/GeneralSans-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'GeneralSans';
  src: url('../public/fonts/GeneralSans-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'GeneralSans';
  src: url('../public/fonts/GeneralSans-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('../public/fonts/Manrope-Medium.ttf') format('ttf');
  font-weight: 500;
  font-style: normal;
}


* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#root {
  overscroll-behavior: none !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

:root::-webkit-scrollbar{
  display: none;
}

.no-point-event {
  pointer-events: none;
}

body {
  font-family: 'GeneralSans', sans-serif;
  color: white;
  background: #101010;
  height: 100%;
  position: fixed;
  /*overflow-y: scroll;*/
  /*-webkit-overflow-scrolling: touch;*/
}

.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s;
  z-index: 10;
}

.hidden-h1 {
  color: transparent;
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 50%;
}

.bg {
  background: #101010;
}

.fullscreen.notready {
  color: #606060;
}


.fullscreen.ready > div {
  cursor: pointer;
}

.fullscreen.clicked {
  pointer-events: none;
  opacity: 0;
}

.word-line {
  overflow: hidden;
}


.link--kale {
  position: relative;
}


.link--kale::before,
.link--kale::after {
  position: absolute;
  width: 100%;
  height: 1px;
  background: currentColor;
  top: 100%;
  left: 0;
  pointer-events: none;
}

.link--kale::before {
  content: '';
}


.link--kale::before {
  height: 10px;
  top: 100%;
  opacity: 0;
}

.link--kale:hover::before {
  opacity: 1;
  animation: lineUp 0.3s ease forwards;
}

@keyframes lineUp {
  0% {
    transform-origin: 50% 100%;
    transform: scale3d(1, 0.045, 1);
  }

  50% {
    transform-origin: 50% 100%;
    transform: scale3d(1, 1, 1);
  }

  51% {
    transform-origin: 50% 0;
    transform: scale3d(1, 1, 1);
  }

  100% {
    transform-origin: 50% 0;
    transform: scale3d(1, 0.045, 1);
  }
}

.link--kale::after {
  content: '';
  transition: opacity 0.3s;
  opacity: 0;
  transition-delay: 0s;
}

.link--kale:hover::after {
  opacity: 1;
  transition-delay: 0.3s;
}
