

.scrollbar_container {
    position: fixed;
    /*margin-right: 90px;*/
    right: 111px;
    top: 55%;
    z-index: 10;
    transform: translate(0, -50%);
    height: fit-content;
    display: flex;
    flex-direction: column;
    /*gap: 10px;*/
}


.scrollbar_dot {
    height: 30px;
    position: relative;
    z-index: 2;
    width: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.scrollbar_dot div {
    position: absolute;
    /*background-color: rgba(255, 255, 255, 0.1);*/
    border: 1px solid white;
    border-radius: 40px;
    height: 8px;
    width: 8px;
}

.scrollbar_dot .resized {
    /*background-color: rgba(255, 255, 255, 0.1);*/
    transition: 0.5s;
}

.scrollbar_dot .static {
    background-color: rgba(255, 255, 255, 0.1);

}

.scrollbar_dot:hover > .resized {
        /*height: 62px;*/
        /*width: 62px;*/
    background-color: rgba(255, 255, 255, 1);

}
    /*background-color: rgba(255, 255, 255, 0.1);*/

.scrollbar_dot .active {
    /*height: 62px;*/
    /*width: 62px;*/
    background-color: rgba(255, 255, 255, 1);

    position: absolute;
    /*background-color: rgba(255, 255, 255, 0.1);*/
    border: 1px solid white;
}

.works_container {
    z-index: 10;
    /*padding-top: 25vh;*/
    position: relative;
    /*top: 65%;*/
    /*transform: translate(0, -50%);*/
    height: 100%;
    left: 100px;
    display: flex;
    max-width: 450px;
    align-items: center;
    opacity: 0;
}


.works_info_container {
    font-size: 24px;
    top: 50%;
    transform: translate(0, -50%);
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.works_info {
    width: 475px;
}

.works_title {
    font-size: 78px;
    font-weight: 600;
    line-height: 0.9;
    margin: 0 0 50px;
    font-kerning: none;
}

/*.works_info_container p {*/

/*}*/

.works_description {
    margin: 0 0 30px;
    font-weight: 400;
    font-size: 22px;
    line-height: 130%;
    font-kerning: none;
    text-wrap: balance;
    width: 430px;
    /*text-transform: uppercase;*/
}

.works_tags_container {
    position: absolute;
    bottom: 10%;
}

/*.works_tags_container {*/
/*    position: absolute;*/
/*}*/

.works_tags_title {
    font-size: 20px;
    margin-bottom: 16px;
}

.works_tags {
    font-size: 26px;
    font-kerning: none;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    /*width: 550px;*/

}

.works_tag {
    font-size: 16px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 40px;
    padding: 2px 14px;
}

.works_info_container .case_button {
    font-size: 16px;
    border: 1px solid white;
    padding: 1em 1.5em;
    border-radius: 50px;
    background: none;
    outline: none;
    color: white;
    cursor: pointer;
    font-family: 'GeneralSans', sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.works_info_container .case_button_arrow {
    height: 1em;
    display: inline-block;
    margin-left: 0.4em;
}

@media screen and (max-width: 1919px) {

    .works_container {
        left: 60px;
    }

    .works_title {
        font-size: 64px;
        margin: 0 0 50px 0;
    }

    .works_description {
        font-size: 24px;
        margin: 0 0 30px 0;
        width: 410px;

    }

    .works_tags_title {
        font-size: 18px;
        margin-bottom: 14px;
    }

    .works_tags {
        font-size: 20px;
    }

    .works_info_container .case_button {
        font-size: 16px;
        padding: 0.75em 1.2em;
    }

    /*.works_description {*/
    /*    width: 340px;*/
    /*}*/

    .works_info{
        /*margin: 0 0 30px;*/
        width: 420px;
    }

    .scrollbar_container {
        right: 63px;
    }

    .scrollbar_dot {
        height: 20px;
        width: 20px;
    }

    .scrollbar_dot div {
        height: 6px;
        width: 6px;
    }

    /*.scrollbar_dot:hover > .resized {*/
    /*    height: 40px;*/
    /*    width: 40px;*/
    /*}*/

    /*.scrollbar_dot .active {*/
    /*    height: 40px;*/
    /*    width: 40px;*/
    /*}*/
}

@media screen and (max-width: 1439px) {

    .works_container {
        left: 40px;
    }
    .works_title {
        font-size: 54px;
        margin: 0 0 50px;
    }

    .works_description {
        font-size: 20px;
        margin: 0 0 30px;
    }

    .works_tags_container {
        bottom: 60px;
    }

    .works_tags {
        font-size: 16px;
    }

    .scrollbar_container {
        right: 47px;
    }

    .works_info{
        margin: 0 0 30px;
        width: 340px;
    }

    .works_info_container .case_button {
        font-size: 16px;
        padding: 0.75em 1.2em;
    }

}

@media screen and (max-width: 1023px) {



    /*.works_container {*/
    /*    z-index: 10;*/
    /*    !*padding-top: 25vh;*!*/
    /*    position: relative;*/
    /*    !*top: 65%;*!*/
    /*    !*transform: translate(0, -50%);*!*/
    /*    height: 100%;*/
    /*    left: 173px;*/
    /*    display: flex;*/
    /*    max-width: 420px;*/
    /*    align-items: center;*/
    /*    opacity: 0;*/
    /*}*/

    /*.works_info_container {*/
    /*    font-size: 24px;*/
    /*    top: 50%;*/
    /*    transform: translate(0, -50%);*/
    /*    position: absolute;*/
    /*    display: flex;*/
    /*    flex-direction: column;*/
    /*    align-items: flex-start;*/
    /*}*/


    .works_container {
        /*top: auto;*/
        left: 50%;
        transform: translate(-50%, 0);
        max-width: 550px;
        /*bottom: 66px;*/
    }

    .works_info_container {
        /*left: 50%;*/
        top: auto;
        transform: translate(0, 0);
        bottom: 145px;
    }

    .works_tags_container {
        position: absolute;
        bottom: 30px;
    }

    .works_title {
        font-size: 48px;
        margin: 0 0 18px;
    }
    .works_description {
        font-size: 24px;
        max-width: 375px;
        margin: 0 0 24px;
    }
    .works_info{
        margin: 0 0 20px;
    }
    .scrollbar_container {
        right: 44px;
    }

    .scrollbar_dot {
        height: 20px;
        width: 20px;
    }

    .scrollbar_dot div {
        height: 6px;
        width: 6px;
    }

    /*.scrollbar_dot:hover > .resized {*/
    /*    height: 32px;*/
    /*    width: 32px;*/
    /*}*/

    /*.scrollbar_dot .active {*/
    /*    height: 32px;*/
    /*    width: 32px;*/
    /*}*/

    /*.works_info_container .case_button {*/
    /*    font-size: 10px;*/
    /*    padding: 0.75em 1.2em;*/
    /*}*/

}

@media screen and (max-width: 767px) {
    .works_info_container {
        font-size: 12px;
        margin-left: 20px;
    }
    /*.scrollbar_container {*/
    /*    margin-right: 26px;*/
    /*}*/

    .works_title {
        font-size: 32px;
        margin: 0 0 12px;
    }
    .works_description {
        font-size: 16px;
        margin: 0 0 20px;
        width: 280px;

    }
    .works_info{
        margin: 0 0 20px 0;
        width: 250px;
    }

    .works_tags_title {
        font-size: 12px;
        margin-bottom: 10px;
    }

    .works_tags {
        font-size: 14px;
    }


    .works_info_container {
        /*left: 50%;*/
        bottom: 110px;
    }

    .works_tags_container {
        bottom: -35px;
        margin-left: 20px;

        /*width: 250px;*/
    }

    .works_container {
        bottom: 63px;
        /*transform: none;*/
        /*top: auto;*/
    }

    .scrollbar_container {
        /*margin-right: 90px;*/
        right: 21px;
        top: auto;
        bottom: 10%;
        transform: none;
    }

    .works_info_container .case_button {
        font-size: 12px;
        padding: 0.75em 1.2em;
    }

}

@media (max-width: 1295px) and (max-height: 500px) {


    .works_info_container {
        font-size: 12px;
    }
    .scrollbar_container {
        right: 44px;
        top: 55%;
        transform: translate(0, -50%);
        bottom: auto;
    }
    .works_title {
        font-size: 36px;
        margin: 0 0 12px;
    }
    .works_description {
        font-size: 18px;
        margin: 0 0 24px;
        width: 300px;
    }

    .works_tags_title {
        font-size: 12px;
        margin: 0 0 10px;
    }

    .works_tags {
        font-size: 14px;
    }

    .works_tags_container {
        bottom: 50px;
    }

    .works_info{
        margin: 0 0 20px;
        width: 350px;
    }

    .works_container {
        transform: translate(0, -50%);
        left: 31px;
        top: 55%;
        bottom: auto;
    }

    .works_info_container {
        font-size: 12px;
    }


    .works_info_container .case_button {
        font-size: 10px;
        padding: 0.75em 1.2em;
    }

}
