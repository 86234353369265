.cursor-dot {

    pointer-events: none;
    z-index: 9999;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    opacity: 1;
    transition: filter 0.3s ease-in-out, transform 0.3s ease-in-out, height 0.3s ease-in-out, width 0.3s ease-in-out, opacity 0.3s ease-in-out,max-height 0.3s ease-in-out,max-width 0.3s ease-in-out,-webkit-filter 0.3s ease-in-out;
    width: 14px;
    height: 14px;
    background-color: hsla(0,0%,100%,.01);
    backdrop-filter: invert(100%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.cursor-hover {
    width: 60px;
    height: 60px;
    backdrop-filter: invert(100%);

}

.cursor-text {
    font-size: 14px;
    text-wrap: none;
    font-family: 'Abel', sans-serif;
}

.cursor-hover-link {
    width: 80px;
    height: 80px;
    border: 1px solid white;
    backdrop-filter: invert(0%);
    background: none;
}

.cursor-hover-case-link {
    width: 80px;
    height: 80px;
    border: 1px solid white;
    backdrop-filter: invert(0%);
    background: none;
    text-align: center;
}


@media screen and (max-width: 1024px) {

    .cursor-dot {
        opacity: 0 !important;
    }
}

@media screen and (max-height: 500px) {

    .cursor-dot {
        opacity: 0 !important;
    }
}