.header {
    display: flex;
    width: 100%;
    padding: 0 100px;
    height: 100px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    opacity: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    justify-content: space-between;
    align-items: center;
}

.navigation {
    display: flex;
}



.navigation div {
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    padding: 0 15px;
    margin: 0 15px;
    display: flex;
}

.logo {
    height: 43px;
}

.navigation a {
    outline: none;
    text-decoration: none;
    font-size: 14px;
    margin: 0 15px;
    display: flex;
    align-items: center;
    color: white;
}

/*.arrow {*/
/*    height: 14px;*/
/*    width: 14px;*/
/*    margin-left: 5px;*/
/*}*/


a:hover {
    cursor: pointer;
    /*text-decoration: underline;*/
}


@media screen and (max-width: 1919px) {
    .header {
        height: 76px;
        padding: 0 60px;
    }

    .logo {
        height: 36px;
    }

    .navigation a {
        font-size: 12px;
        margin: 0 15px;
    }

}

@media screen and (max-width: 1439px) {
    .header {
        padding: 0 40px;
    }
}

@media screen and (max-width: 1023px) {
    .header {
        height: 66px;
        padding: 0 40px;
    }

}

@media screen and (max-width: 767px) {
    .header {
        height: 66px;
        padding: 0 20px;
    }

    .logo {
        height: 28px;
    }

    .navigation a {
        font-size: 10px;
        margin: 0 9px;
    }

    .navigation div {
        padding: 0 9px;
        margin: 0 9px;

    }

    .navigation_home {
        display: none !important;
    }
}

@media (max-width: 1295px) and (max-height: 500px) {

    .logo {
        height: 28px;
    }

}